export const BULK_UPDATE_EMPLOYEES_ACTION = "BULK_UPDATE_EMPLOYEES_ACTION"
export const BULK_UPDATE_EMPLOYEES_LOADING = "BULK_UPDATE_EMPLOYEES_ACTION"
export const BULK_UPDATE_EMPLOYEES_SUCCESS = "BULK_UPDATE_EMPLOYEES_ACTION"
export const BULK_UPDATE_EMPLOYEES_ERROR = "BULK_UPDATE_EMPLOYEES_ACTION"

export const ONBOARD_EMPLOYEES_ACTION = "ONBOARD_EMPLOYEES_ACTION";
export const ONBOARD_EMPLOYEES_LOADING = "ONBOARD_EMPLOYEES_LOADING";
export const ONBOARD_EMPLOYEES_SUCCESS = "ONBOARD_EMPLOYEES_SUCCESS";
export const ONBOARD_EMPLOYEES_ERROR = "ONBOARD_EMPLOYEES_ERROR";

export const INVITE_EMPLOYEES_ACTION = "INVITE_EMPLOYEES_ACTION";
export const INVITE_EMPLOYEES_LOADING = "INVITE_EMPLOYEES_LOADING";
export const INVITE_EMPLOYEES_SUCCESS = "INVITE_EMPLOYEES_SUCCESS";
export const INVITE_EMPLOYEES_ERROR = "INVITE_EMPLOYEES_ERROR";

export const UPDATE_EMPLOYEE_ACTION = "UPDATE_EMPLOYEE_ACTION";
export const UPDATE_EMPLOYEE_LOADING = "UPDATE_EMPLOYEE_LOADING";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";

export const CREATE_PAYSTACK_PROFILE_ACTION = "CREATE_PAYSTACK_PROFILE_ACTION";
export const CREATE_PAYSTACK_PROFILE_LOADING = "CREATE_PAYSTACK_PROFILE_LOADING";
export const CREATE_PAYSTACK_PROFILE_SUCCESS = "CREATE_PAYSTACK_PROFILE_SUCCESS";
export const CREATE_PAYSTACK_PROFILE_ERROR = "CREATE_PAYSTACK_PROFILE_ERROR";

export const OVERTIME_LIST_ACTION = "OVERTIME_LIST_ACTION";
export const OVERTIME_LIST_LOADING = "OVERTIME_LIST_LOADING";
export const OVERTIME_LIST_SUCCESS = "OVERTIME_LIST_SUCCESS";
export const OVERTIME_LIST_ERROR = "OVERTIME_LIST_ERROR";

export const GET_OVERTIME_ACTION = "GET_OVERTIME_ACTION";
export const GET_OVERTIME_LOADING = "GET_OVERTIME_LOADING";
export const GET_OVERTIME_SUCCESS = "GET_OVERTIME_SUCCESS";
export const GET_OVERTIME_ERROR = "GET_OVERTIME_ERROR";

export const APPROVE_OVERTIME_ACTION = "APPROVE_OVERTIME_ACTION";
export const APPROVE_OVERTIME_LOADING = "APPROVE_OVERTIME_LOADING";
export const APPROVE_OVERTIME_SUCCESS = "APPROVE_OVERTIME_SUCCESS";
export const APPROVE_OVERTIME_ERROR = "APPROVE_OVERTIME_ERROR";

export const REJECT_OVERTIME_ACTION = "REJECT_OVERTIME_ACTION";
export const REJECT_OVERTIME_LOADING = "REJECT_OVERTIME_LOADING";
export const REJECT_OVERTIME_SUCCESS = "REJECT_OVERTIME_SUCCESS";
export const REJECT_OVERTIME_ERROR = "REJECT_OVERTIME_ERROR";

export const LEAVE_LIST_ACTION = "LEAVE_LIST_ACTION";
export const LEAVE_LIST_LOADING = "LEAVE_LIST_LOADING";
export const LEAVE_LIST_SUCCESS = "LEAVE_LIST_SUCCESS";
export const LEAVE_LIST_ERROR = "LEAVE_LIST_ERROR";

export const CREATE_LEAVE_ACTION = "CREATE_LEAVE_ACTION";
export const CREATE_LEAVE_LOADING = "CREATE_LEAVE_LOADING";
export const CREATE_LEAVE_SUCCESS = "CREATE_LEAVE_SUCCESS";
export const CREATE_LEAVE_ERROR = "CREATE_LEAVE_ERROR";
