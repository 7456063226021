import React from 'react';
import { 
  BarChart, 
  Bar, 
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  CartesianGrid 
} from 'recharts';

const ChartComponent = ({ 
  data, 
  xKey, 
  dataKey,
  chartType,
  chartColor = "#FFFFFF",
  chartConfig = {}
}) => {
  if (!data || data.length === 0 || !chartType) {
    return <div>No data available for the chart</div>;
  }

  const renderChart = () => {
    switch (chartType.toLowerCase()) {
      case 'bar':
        return (
          <BarChart data={data}>
            <XAxis dataKey={xKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={dataKey} fill={chartColor} {...chartConfig} />
          </BarChart>
        );

      case 'line':
        return (
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            style={{ backgroundColor: "#3E83FA" }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xKey} stroke="#FFFFFF" /> 
            <YAxis stroke="#FFFFFF" />
            <Tooltip 
              cursor={{ strokeDasharray: '3 3', stroke: '#FFFFFF' }}
              contentStyle={{ backgroundColor: '#3E83FA', color: '#FFFFFF' }} 
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey={dataKey} 
              stroke={chartColor} 
              strokeWidth={2} 
              dot={{ stroke: '#FFFFFF', strokeWidth: 2 }}
              activeDot={{ stroke: '#FFFFFF', strokeWidth: 3 }}
              {...chartConfig} 
            />
          </LineChart>
        );

      case 'pie':
        return (
          <PieChart>
            <Pie
              data={data}
              dataKey={dataKey}
              nameKey={xKey}
              fill={chartColor}
              {...chartConfig}
            />
            <Tooltip />
            <Legend />
          </PieChart>
        );

      default:
        return null;
    }
  };

  return (
    <ResponsiveContainer width="80%" height={300}>
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default ChartComponent;