import axios from 'axios';
import { Constants } from "../../lib/constant";
import { domainUrl } from '../../lib/axiosConfig'

export const fetchDataFromApi = (endpoint, filters) => {
    const apiUrl = `${domainUrl}/reports/${endpoint}`;

    return new Promise((resolve, reject) => {
        axios.get(apiUrl, { params: filters })
            .then(response => {
                let filteredData = response.data;

                const parsePeriod = (period) => {
                    const [month, day] = period.split(' ');
                    const year = new Date().getFullYear(); 
                    const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
                    return new Date(year, monthIndex, parseInt(day));
                };

                if (Array.isArray(filteredData)) {
                    if (filters) {
                        filteredData = filteredData.filter(item => {
                            return Object.entries(filters).every(([key, value]) => {
                                if (!value) return true;

                                switch (key) {
                                    case 'start_date':
                                        return parsePeriod(item.period) >= new Date(value);
                                    case 'end_date':
                                        return parsePeriod(item.period) <= new Date(value);
                                    case 'company':
                                        return item.company === value || item.company_name === value;
                                    default:
                                        return true;
                                }
                            });
                        });
                    }
                    resolve(filteredData);
                } else {
                    reject(new Error('Expected an array but received something else'));
                }
            })
            .catch(error => {

                console.error('Error fetching data:', error);
                reject(error);
            });
    });
};
