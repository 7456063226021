import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import CommonSwitch from "../switch";
import theme from "../../theme";
import CommonTable from "../table";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeListAction } from "../../containers/dashboard/action";
import history from "../../history";
import ConfirmSwitchModal from "../confirmSwitchModal";
import { moneyValue } from "../../utils";

const useStyles = makeStyles(() => ({
  detailContainer: {
    marginTop: "30px",
    "& .block": {
      cursor: "pointer",
      background: theme.colors.White,
      boxShadow: "0px 2px 15px #D0CFDB99",
      borderRadius: "15px",
      padding: "30px",
      display: "flex",
      marginBottom: "15px",
      "& .left": {
        flex: "0.6",
        display: "flex",
        "& .logoContainer": {
          height: "85px",
          width: "85px",
          borderRadius: "50%",
          border: `1px solid ${theme.colors.Zumthor}`,
          backgroundColor: theme.colors.White,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img": {
            height: "100%",
            width: "100%",
            objectFit: "contain",
            borderRadius: "50%",
          },
        },
        "& .comInfoContainer": {
          marginLeft: "25px",
          color: theme.colors.Violet,
          width: "60%",
          "& .comName": {
            fontSize: "22px",
            fontFamily: theme.fontFamily.MARKPROBOLD,
            marginBottom: "10px",
          },
          "& .flexContainer": {
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
            "& .changeLink": {
              color: theme.colors.DodgerBlue,
              fontFamily: theme.fontFamily.MARKPRO,
              marginLeft: "5px",
              display: "none",
            },
            "& .label": { fontFamily: theme.fontFamily.MARKPRO, flex: 0.5 },
            "& .val": {
              fontFamily: theme.fontFamily.MARKPROBOLD,
              flex: 0.5,
            },
          },
        },
      },
      "& .right": {
        flex: "0.4",
        display: "flex",
        // alignItems: "center",
        justifyContent: "space-between",
        "& .empNumberContainer": {
          color: theme.colors.Violet,
          "& .label": {
            fontSize: "12px",
            fontFamily: theme.fontFamily.MARKPRO,
          },
          "& .val": {
            fontSize: "16px",
            fontFamily: theme.fontFamily.MARKPROHEAVY,
            "& .currency": {
              fontSize: "10px",
              position: "relative",
              bottom: "4px",
              marginRight: "2px",
            },
          },
        },
        "& .borderContainer": {
          border: `1px solid ${theme.colors.Zumthor}`,
          height: "215px",
          margin: "0px 10px",
        },
      },
    },
  },
  headerName: {
    color: theme.colors.Violet,
    fontSize: "13px",
    fontFamily: theme.fontFamily.MARKPROHEAVY,
    opacity: "0.3",
  },
  employeeName: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  bankName: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
  },
  salary: {
    color: theme.colors.Violet,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROBOOK,
    "& .currency": {
      fontSize: "10px",
      position: "relative",
      bottom: "3px",
    },
  },
  adminTube: {
    color: "#22D559",
    backgroundColor: "#E4FDEC",
    fontSize: "10px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    borderRadius: "10px",
    padding: "3px 5px",
  },
}));

function CompanyDetail({
  companyInfo,
  updateCompanyMethod,
  updateEmployeeMethod,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getEmployeeListMethod = useCallback(
    (data) => dispatch(getEmployeeListAction(data)),
    [dispatch]
  );

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;
  const [confirmSwitch, setConfirmSwitch] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [switchRecord, setSwitchRecord] = useState(null);
  const [switchMethod, setSwitchMethod] = useState(null);

  useEffect(() => {
    if (
      confirmation === "yes" &&
      switchRecord &&
      switchRecord.id &&
      switchMethod
    ) {
      if (switchMethod === "updateEmployeeMethod")
        updateEmployeeMethod(
          {
            is_active: !switchRecord.is_active,
          },
          switchRecord.id
        );
      if (switchMethod === "updateCompanyMethod")
        updateCompanyMethod({
          company_id: switchRecord.id,
          data: {
            is_active: !switchRecord.is_active,
          },
        });
      setSwitchRecord(null);
      setSwitchMethod(null);
      setConfirmation(null);
    }
  }, [confirmation]);

  useEffect(() => {
    if (companyInfo?.id) {
      getEmployeeListMethod({ page, page_size, company_id: companyInfo.id });
    }
  }, [companyInfo, getEmployeeListMethod, page, page_size]);

  useEffect(() => {
    if (companyDashboardReducer?.updateEmployeeSuccess && companyInfo?.id) {
      getEmployeeListMethod({ page, page_size, company_id: companyInfo.id });
    }
  }, [
    companyDashboardReducer,
    companyInfo,
    getEmployeeListMethod,
    page,
    page_size,
  ]);

  useEffect(() => {
    if (dashboardReducer?.employeeList?.count) {
      setTotalPage(Math.ceil(dashboardReducer.employeeList.count / page_size));
    }
  }, [dashboardReducer]);

  return companyInfo ? (
    <div className={classes.detailContainer}>
      <div className="block">
        <div className="left">
          <div className="logoContainer">
            <img
              src={
                companyInfo.image
                  ? companyInfo.image
                  : "/images/no-image-found.png"
              }
              alt="comLogo"
            ></img>
          </div>
          <div className="comInfoContainer">
            <div className="comName">{companyInfo.name}</div>
            {companyInfo.admins?.length ? (
              <>
                <div className="flexContainer">
                  <div className="label">Admin:</div>
                  <div className="val">
                    <span>
                      {companyInfo.admins[0]?.first_name}{" "}
                      {companyInfo.admins[0]?.last_name}
                    </span>
                    <a href="#" className="changeLink">
                      Change
                    </a>
                  </div>
                </div>

                <div className="flexContainer" style={{ marginTop: "10px" }}>
                  <div className="label">Email:</div>
                  <div className="val">
                    <span>{companyInfo.admins[0]?.email}</span>
                  </div>
                </div>

                <div className="flexContainer" style={{ marginTop: "10px" }}>
                  <div className="label">Phone Number:</div>
                  <div className="val">
                    {companyInfo.admins[0]?.profile?.phone}
                  </div>
                </div>
              </>
            ) : null}
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Country:</div>
              <div className="val">
                {companyInfo?.country && loginReducer?.countryOptions?.length
                  ? loginReducer.countryOptions.find(
                      (each) => each.id === companyInfo.country
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Currency:</div>
              <div className="val">
                {companyInfo?.currency && loginReducer?.currencyOptions?.length
                  ? loginReducer.currencyOptions.find(
                      (each) => each.id === companyInfo.currency
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer" style={{ marginTop: "10px" }}>
              <div className="label">Time zone:</div>
              <div className="val">
                {companyInfo?.timezone && loginReducer?.timeZoneOptions?.length
                  ? loginReducer.timeZoneOptions.find(
                      (each) => each.id === companyInfo.timezone
                    ).name
                  : ""}
              </div>
            </div>
            <div className="flexContainer">
              <div className="label">Account:</div>
              <div className="val">
                <span style={{ fontSize: "12px" }}>Activated</span>
                <CommonSwitch
                  name={companyInfo.id}
                  checked={companyInfo.is_active}
                  disabled={!updateCompanyMethod}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmSwitch(true);
                    setSwitchRecord(companyInfo);
                    setSwitchMethod("updateCompanyMethod");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">Total Number of Employees</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {companyInfo.total_employees}
              </div>
            </div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">Total Number of Registered Employees</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {companyInfo.registered_employees}
              </div>
            </div>
            <div className="empNumberContainer">
              <div className="label">Total Monthly Net Salaries</div>
              <div className="val" style={{ padding: "10px 0px" }}>
                <span className={"currency"}>{companyInfo.currency}</span>{" "}
                {moneyValue(companyInfo.net_salaries)}
              </div>
            </div>
          </div>
          <div className="borderContainer" />
          <div>
            <div
              className="empNumberContainer"
              style={{ marginBottom: "20px" }}
            >
              <div className="label">
                Days designated as <br />
                Work Days
              </div>
              <div className="val" style={{ padding: "10px 0px" }}>
                {companyInfo.days}{" "}
                {companyInfo?.formula
                  ? companyInfo.formula === "CALENDAR-DAYS"
                    ? "Calendar Days"
                    : companyInfo.formula === "BUSINESS-DAYS"
                    ? "Working Days"
                    : ""
                  : ""}
              </div>
            </div>
            {companyInfo?.workdays?.length ? (
              <div className="empNumberContainer">
                <div className="label">Working Days</div>
                <div className="val" style={{ padding: "10px 0px" }}>
                  {companyInfo.workdays.join(", ")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {!dashboardReducer?.loading ? (
        <div className="tableContainer">
          <CommonTable
            fullRadius={true}
            columns={[
              {
                Header: "Employee Name",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record, index) => {
                  return (
                    <div className={classes.employeeName}>
                      {record?.first_name} {record?.last_name}{" "}
                      {record?.is_admin ? (
                        <span className={classes.adminTube}>Admin</span>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                },
              },
              {
                Header: "Bank Name",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.bankName}>
                      {record?.banks?.length ? record.banks[0].name : ""}
                    </div>
                  );
                },
              },
              {
                Header: "Net Monthly Salary",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{companyInfo.currency}</span>{" "}
                      {moneyValue(record?.profile?.salary)}
                    </div>
                  );
                },
              },
              {
                Header: "Total Withdrawn",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div className={classes.salary}>
                      <span className="currency">{companyInfo.currency}</span>{" "}
                      {moneyValue(record?.profile?.withdrawals)}
                    </div>
                  );
                },
              },
              // {
              //   Header: "Leaves",
              //   accessor: "id",
              //   HeaderCell: (data) => {
              //     return <div className={classes.headerName}>{data}</div>;
              //   },
              //   Cell: (data, record) => {
              //     return (
              //       <div className={classes.salary}>
              //         <span className="currency">{companyInfo.currency}</span>{" "}
              //         {record?.profile?.leaves}
              //       </div>
              //     );
              //   },
              // },
              // {
              //   Header: "Deductions",
              //   accessor: "id",
              //   HeaderCell: (data) => {
              //     return <div className={classes.headerName}>{data}</div>;
              //   },
              //   Cell: (data, record) => {
              //     return (
              //       <div className={classes.salary}>
              //         <span className="currency">{companyInfo.currency}</span>{" "}
              //         {record?.profile?.deductions}
              //       </div>
              //     );
              //   },
              // },
              {
                Header: "Remaining Balance",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <div
                      className={classes.salary}
                      style={{ fontFamily: theme.fontFamily.MARKPROHEAVY }}
                    >
                      <span className="currency">{companyInfo.currency}</span>{" "}
                      {moneyValue(record?.profile?.balance)}
                    </div>
                  );
                },
              },
              {
                Header: "Account Status",
                accessor: "id",
                HeaderCell: (data) => {
                  return <div className={classes.headerName}>{data}</div>;
                },
                Cell: (data, record) => {
                  return (
                    <CommonSwitch
                      name={data}
                      checked={record?.is_active}
                      disabled={!updateEmployeeMethod}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmSwitch(true);
                        setSwitchRecord(record);
                        setSwitchMethod("updateEmployeeMethod");
                      }}
                    />
                  );
                },
              },
            ]}
            data={dashboardReducer?.employeeList?.data}
            onRowClick={(rowIndex) => {
              const record = dashboardReducer.employeeList.data[rowIndex];

              history.push(
                `/backoffice/dashboard/companies?companyId=${record?.company?.id}&employeeId=${record?.id}`
              );
            }}
          />
          {dashboardReducer?.employeeList?.data?.length ? (
            <div className={"pagination"}>
              <Pagination
                page={page}
                count={totalPage}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <ConfirmSwitchModal
        openModal={confirmSwitch}
        setConfirmation={(confirmation) => {
          setConfirmSwitch((temp) => !temp);
          setConfirmation(confirmation);
        }}
      />
    </div>
  ) : null;
}

export default CompanyDetail;
