import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
    position: "relative",
    zIndex: 1,
  },
  table: {
    minWidth: "100%",
    borderCollapse: "collapse",
    border: "1px solid #e0e0e0",
    tableLayout: "fixed",
  },
  tableHeader: {
    backgroundColor: "#f3f4f6",
    color: "#333",
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  tableCell: {
    border: "1px solid #e0e0e0",
    padding: "12px 16px",
    textAlign: "left",
    minWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  oddRow: {
    backgroundColor: "#fff",
  },
  evenRow: {
    backgroundColor: "#f9fafb",
  },
  subHeader: {
    backgroundColor: "#e5e7eb",
    textAlign: "center",
    fontWeight: "bold",
    position: "sticky",
    top: "43px",
    zIndex: 1,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  paginationButton: {
    margin: "0 5px",
    padding: "8px 12px",
    cursor: "pointer",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    backgroundColor: "#fff",
    "&:hover:not(:disabled)": {
      backgroundColor: "#f0f0f0",
    },
    "&:disabled": {
      cursor: "not-allowed",
      color: "#aaa",
    },
    '&[style*="bold"]': {
      backgroundColor: "#007BFF",
      color: "#fff",
      fontWeight: "bold",
    },
  },
}));

const formatCurrency = (value) => {
  if (typeof value !== "number") {
    console.error("Invalid value type for currency formatting:", value);
    return value;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  }).format(value);
};

const safeFormatCurrency = (value) => {
  const numericValue = parseFloat(value);
  return !isNaN(numericValue) ? formatCurrency(numericValue) : value;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-CA");
};

const TableComponent = ({ data, columns, endpoint }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 12;

  const handleNextPage = () => {
    if (currentPage * rowsPerPage < data.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentRows = data.slice(startIndex, startIndex + rowsPerPage);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const getPageNumbers = () => {
    return Array.from({ length: totalPages }, (_, index) => index + 1)
      .filter((page) => {
        return (
          page === 1 ||
          page === totalPages ||
          (page >= currentPage - 2 && page <= currentPage + 2)
        );
      })
      .reduce((acc, page, i, arr) => {
        if (i > 0 && page !== arr[i - 1] + 1) {
          acc.push("...");
        }
        acc.push(page);
        return acc;
      }, []);
  };

  if (endpoint === "transactionanalysis") {
    const ranges = data[0]?.ranges || [];

    return (
      <div className={classes.tableWrapper}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.tableHeader}>
              <th className={classes.tableCell} rowSpan="2">
                Period
              </th>
              {ranges.map((range, index) => (
                <th key={index} className={classes.tableCell} colSpan="2">
                  {range.range_label}
                </th>
              ))}
              <th className={classes.tableCell} rowSpan="2">
                Total Transactions
              </th>
              <th className={classes.tableCell} rowSpan="2">
                Total Amount
              </th>
            </tr>
            <tr className={classes.subHeader}>
              {ranges.map((_, index) => (
                <React.Fragment key={index}>
                  <th className={classes.tableCell}>Number</th>
                  <th className={classes.tableCell}>Rate</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? classes.oddRow : classes.evenRow}
              >
                <td className={classes.tableCell}>{row.period}</td>
                {row?.ranges?.map((range, rangeIndex) => (
                  <React.Fragment key={rangeIndex}>
                    <td className={classes.tableCell}>{range.number}</td>
                    <td className={classes.tableCell}>{range.rate}%</td>
                  </React.Fragment>
                ))}
                <td className={classes.tableCell}>{row.total_transactions}</td>
                <td className={classes.tableCell}>{row.total_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {data.length > rowsPerPage && (
          <div className={classes.pagination}>
            <button
              className={classes.paginationButton}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                className={classes.paginationButton}
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                style={{
                  fontWeight: currentPage === page ? "bold" : "normal",
                  cursor: page === "..." ? "default" : "pointer",
                }}
                disabled={page === "..."}
              >
                {page}
              </button>
            ))}
            <button
              className={classes.paginationButton}
              onClick={handleNextPage}
              disabled={currentPage * rowsPerPage >= data.length}
            >
              Next
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableHeader}>
            {columns.map((col, index) => (
              <th key={index} className={classes.tableCell}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? classes.oddRow : classes.evenRow}
            >
              {columns.map((col, colIndex) => {
                const key = col.replace(/ /g, "_").toLowerCase();
                const value = row[key];

                let formattedValue;

                if (key === "start_date") {
                  formattedValue = formatDate(value);
                } else if (
                  ![
                    "net_salary",
                    "payment",
                    "fees",
                    "total_payment",
                    "total_amount",
                    "total_salaries",
                    "total_request_amounts",
                    "total_revenue_from_fees",
                    "total_requested_amount",
                  ].includes(key)
                ) {
                  formattedValue = value;
                } else {
                  formattedValue = safeFormatCurrency(value);
                }

                return (
                  <td key={colIndex} className={classes.tableCell}>
                    {Array.isArray(formattedValue)
                      ? formattedValue.map((item, idx) => (
                          <div key={idx}>
                            {Object.entries(item).map(
                              ([itemKey, itemValue]) => (
                                <div key={itemKey}>
                                  {itemKey}: {itemValue}
                                </div>
                              )
                            )}
                          </div>
                        ))
                      : typeof formattedValue === "object" &&
                        formattedValue !== null
                      ? Object.entries(formattedValue).map(
                          ([objectKey, objectValue], idx) => (
                            <div key={idx}>
                              {objectKey}: {safeFormatCurrency(objectValue)}
                            </div>
                          )
                        )
                      : formattedValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {data.length > rowsPerPage && (
        <div className={classes.pagination}>
          <button
            className={classes.paginationButton}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          {getPageNumbers().map((page, index) => (
            <button
              key={index}
              className={classes.paginationButton}
              onClick={() => typeof page === "number" && handlePageChange(page)}
              style={{
                fontWeight: currentPage === page ? "bold" : "normal",
                cursor: page === "..." ? "default" : "pointer",
              }}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}
          <button
            className={classes.paginationButton}
            onClick={handleNextPage}
            disabled={currentPage * rowsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default TableComponent;
