import React, { useState, useCallback, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import theme from "../../theme";
import CommonButton from "../button";
import CompanyList from "./list";
import CompanyDetail from "./detail";
import InviteCompany from "./inviteCompany";
import EmployeeDetail from "../employees/employeeDetail";
import {
  getCompanyListAction,
  getCompanyInfoAction,
  inviteCompanyAction,
  updateCompanyAction,
} from "../../containers/dashboard/action";
import {createPayStackProfileAction, updateEmployeeAction} from "../../containers/companyDashboard/action";
import Loading from "../loading";
import history from "../../history";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
      "& .deactiveLabel": { color: "#9FC1FD", cursor: "pointer" },
      "& .activeLabel": {
        color: theme.colors.DodgerBlue,
        cursor: "pointer",
      },
    },
  },
  backLink: {
    marginTop: "15px",
    color: theme.colors.DodgerBlueLight,
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    "& .label": { marginLeft: "5px", position: "relative", top: 2 },
  },
}));

function Companies({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const page_size = 10;

  const getCompanyListMethod = useCallback(
    (data) => dispatch(getCompanyListAction(data)),
    [dispatch]
  );

  const getCompanyInfoMethod = useCallback(
    (data) => dispatch(getCompanyInfoAction(data)),
    [dispatch]
  );

  const inviteCompanyMethod = useCallback(
    (data) => dispatch(inviteCompanyAction(data)),
    [dispatch]
  );

  const updateCompanyMethod = useCallback(
    (data) => dispatch(updateCompanyAction(data)),
    [dispatch]
  );

  const updateEmployeeMethod = useCallback(
    (data, employee_id) => dispatch(updateEmployeeAction(data, employee_id)),
    [dispatch]
  );

  const createPayStackProfileActionMethod = useCallback(
    (data) => dispatch(createPayStackProfileAction(data)),
    [dispatch]
  );

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const [companyDetail, setCompanyDetail] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [inviteCompany, setInviteCompany] = useState(false);

  useEffect(() => {
    if (location?.search) {
      const parsed = queryString.parse(location.search);

      if (parsed?.companyId) {
        if (
          dashboardReducer?.companyList?.data?.length &&
          (!companyDetail?.id || companyDetail.id !== parsed.companyId)
        ) {
          const record = dashboardReducer?.companyList?.data.find(
            (each) => each?.id === parsed?.companyId
          );
          if (record) {
            setCompanyDetail(record);
          } else {
            setCompanyDetail(null);
          }
        }
      } else {
        setCompanyDetail(null);
      }

      if (parsed?.employeeId) {
        if (
          dashboardReducer?.employeeList?.data?.length &&
          (!employeeDetail?.id || employeeDetail.id !== parsed.employeeId)
        ) {
          const record = dashboardReducer?.employeeList?.data.find(
            (each) => each?.id === parsed?.employeeId
          );
          if (record) {
            setEmployeeDetail(record);
          } else {
            setEmployeeDetail(null);
          }
        }
      } else {
        setEmployeeDetail(null);
      }
    } else {
      setCompanyDetail(null);
      setEmployeeDetail(null);
    }
  }, [location, employeeDetail, companyDetail, dashboardReducer]);

  useEffect(() => {
    getCompanyListMethod({ page, page_size });
  }, [getCompanyListMethod, page, page_size]);

  useEffect(() => {
    if (dashboardReducer?.success) {
      getCompanyListMethod({ page, page_size });
      setInviteCompany(false);
      if (companyDetail?.id) {
        getCompanyInfoMethod(companyDetail.id);
      }
    }
  }, [
    dashboardReducer,
    getCompanyListMethod,
    companyDetail,
    getCompanyInfoMethod,
    page,
    page_size,
  ]);

  useEffect(() => {
    if (companyDetail?.id) {
      getCompanyInfoMethod(companyDetail.id);
    }
  }, [companyDetail, getCompanyInfoMethod]);

  useEffect(() => {
    if (dashboardReducer?.companyList?.count) {
      setTotalPage(Math.ceil(dashboardReducer.companyList.count / page_size));
    }
  }, [dashboardReducer]);

  useEffect(() => {
    if (
      dashboardReducer?.error?.length ||
      companyDashboardReducer?.error?.length
    ) {
      const ele = document.getElementsByClassName("error-container");
      if (ele && ele.length) ele[0].scrollIntoView(false);
    }
  }, [dashboardReducer, companyDashboardReducer]);

  return (
    <div className={classes.root}>
      <Loading
        showLoader={
          dashboardReducer?.loading || companyDashboardReducer?.loading
        }
      />
      <div className={classes.headerContainer}>
        <div className="title">
          <div>Companies</div>
          {companyDetail || employeeDetail ? (
            <div>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "#9FC1FD" }}
                  />
                }
              >
                <div
                  className="deactiveLabel"
                  onClick={() => {
                    setInviteCompany(false);
                    history.push("/backoffice/dashboard/companies");
                  }}
                >
                  Companies
                </div>
                <div
                  className={employeeDetail ? "deactiveLabel" : "activeLabel"}
                  onClick={() => {
                    setInviteCompany(false);
                    history.push(
                      `/backoffice/dashboard/companies?companyId=${companyDetail?.id}`
                    );
                  }}
                >
                  {companyDetail?.name}
                </div>
                {employeeDetail ? (
                  <div className="activeLabel">
                    {employeeDetail?.first_name} {employeeDetail?.last_name}
                  </div>
                ) : null}
              </Breadcrumbs>
            </div>
          ) : null}
        </div>
        {!(companyDetail || employeeDetail || inviteCompany) ? (
          <div>
            <CommonButton
              type="button"
              text="Invite Company"
              stylevariant="primary"
              onClick={() => {
                setInviteCompany(true);
              }}
              style={{ padding: "5px 40px" }}
              fontSize="18px"
              fontFamily={theme.fontFamily.MARKPROHEAVY}
            />
          </div>
        ) : null}
      </div>
      {inviteCompany ? (
        <div
          className={classes.backLink}
          onClick={() => {
            setInviteCompany(false);
          }}
        >
          <img src="/images/right-arrow.png" alt="right-arrow" />
          <span className="label">Back</span>
        </div>
      ) : null}
      {dashboardReducer?.error?.length ||
      companyDashboardReducer?.error?.length ? (
        <div className={"error-container"}>
          {dashboardReducer?.error?.length
            ? dashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))
            : null}
          {companyDashboardReducer?.error?.length
            ? companyDashboardReducer.error.map((each, index) => (
                <div key={index}>{each}</div>
              ))
            : null}
        </div>
      ) : null}
      {inviteCompany ? (
        <InviteCompany
          inviteCompanyMethod={inviteCompanyMethod}
          dashboardReducer={dashboardReducer}
        />
      ) : employeeDetail ? (
        <EmployeeDetail
          employeeDetail={employeeDetail}
          updateEmployeeMethod={updateEmployeeMethod}
          createPayStackProfileActionMethod={createPayStackProfileActionMethod}
        />
      ) : companyDetail ? (
        <CompanyDetail
          companyInfo={dashboardReducer?.companyInfo}
          updateCompanyMethod={updateCompanyMethod}
          updateEmployeeMethod={updateEmployeeMethod}
        />
      ) : (
        <>
          <CompanyList
            data={dashboardReducer?.companyList?.data}
            updateCompanyMethod={updateCompanyMethod}
          />
          {dashboardReducer?.companyList?.data?.length ? (
            <div className={"pagination"}>
              <Pagination
                page={page}
                count={totalPage}
                showFirstButton
                showLastButton
                onChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}

export default Companies;
