import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar";
import Overview from "../../components/overview";
import Transactions from "../../components/transaction";
import Companies from "../../components/companies";
import Exchanges from "../../components/exchanges";
import AdminReports from "../../components/adminReports";
// import Deductions from "../../components/deductions";
import Loading from "../../components/loading";
import Countries from "../../components/countries";

const useStyles = makeStyles(() => ({ root: { display: "flex" } }));

function Dashboard({ match, location }) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("overview");

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  useEffect(() => {
    if (match?.params?.activeTab && match.params.activeTab !== activeTab) {
      setActiveTab(match.params.activeTab);
    }
  }, [match, activeTab, setActiveTab]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Sanaam - ${activeTab}`}</title>
        <meta property="og:title" content={`Sanaam - ${activeTab}`} />
      </Helmet>
      <Loading
        showLoader={
          dashboardReducer?.loading || companyDashboardReducer?.loading
        }
      />
      <Sidebar
        baseURL={"/backoffice/dashboard"}
        activeTab={activeTab}
        tabs={[
          { name: "Overview", icon: "Overview Icon.svg", isActive: true, pathName: "overview" },
          {
            name: "Transactions",
            icon: "Transaction Icon.svg",
            isActive: true,
            pathName: "transactions"
          },
          { name: "Companies", icon: "Company Icon.svg", isActive: true, pathName: "companies" },
          {
            name: "Reports",
            icon: "Report Icon.svg",
            isActive: true,
            pathName: "admin-reports"
          },
        ]}
      />
      {activeTab === "overview" && <Overview />}
      {activeTab === "transactions" && <Transactions />}
      {activeTab === "companies" && <Companies location={location} />}
      {activeTab === "Exchanges" && <Exchanges />}
      {activeTab === "admin-reports" && <AdminReports />}
      {/* {activeTab === "Deductions" && <Deductions />} */}
      {activeTab === "Countries" && <Countries />}
    </div>
  );
}

export default Dashboard;
