import React, { useState, useEffect } from 'react';
import { fetchDataFromApi } from './api';
import theme from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChartComponent from './Chart';
import FilterForm from './FilterForm';
import TableComponent from './TableComponent';
import { analysisConfig } from './AnalysisConfig';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: theme.colors.White,
        width: "100%",
        minHeight: "calc(100vh - 185px)",
        padding: "45px 90px",
        marginLeft: "220px",
    },
    select: {
        padding: "8px 12px",
        borderRadius: "8px",
        border: "1px solid #D1D5DB",
        fontSize: "14px",
        fontWeight: "500",
        width: "200px",
        marginBottom: "16px",
        outline: "none",
        '&:hover': {
            borderColor: "#10B981",
        },
        '&:focus': {
            borderColor: "#10B981",
        },
    },
    label: {
        fontSize: "16px",
        fontWeight: "600",
        marginBottom: "8px",
        marginRight: '3px',
        color: "#333",
    },
    backdrop: {
        zIndex: 999999,
        color: "#fff",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    circularProgress: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        color: theme.colors.DodgerBlue,
    },
    h2: {
        fontSize: "1.125rem",
        fontWeight: 600,
        marginTop: "1.5rem",
    },
    noGraph: {
        fontSize: "1rem",
        fontWeight: 500,
        color: "#888",
        textAlign: "center",
        marginTop: "20px",
    }
}));


const AdminReports = () => {
    const classes = useStyles();
    const [endpoint, setEndpoint] = useState('revenueanalysis');
    const [filterStates, setFilterStates] = useState({
        revenueanalysis: {},
        companyanalysis: {},
        clientanalysis: {},
        transactionanalysis: {},
        userstats: {},
        salaryrevenueanalysis: {}
    });
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState(analysisConfig);
    const [companies, setCompanies] = useState([]);
    const [validationError, setValidationError] = useState('');

    const { columns, xKey, barKey, chartType, filters: filterConfig, pathName } = config[endpoint];

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const companiesData = await fetchDataFromApi('companyanalysis', {});
                const companyOptions = companiesData.map(company => ({
                    value: company.company,
                    label: company.company
                }));
                setCompanies(companyOptions);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();
    }, []);

    useEffect(() => {
        if (companies.length > 0) {
            setConfig(prev => {
                const newConfig = { ...prev };
                Object.keys(newConfig).forEach(key => {
                    const configFilters = newConfig[key].filters;
                    if (configFilters) {
                        newConfig[key].filters = configFilters.map(filter => {
                            if ((filter.name === 'company_id' || filter.name === 'company') && 
                                (filter.type === 'select' || filter.type === 'dropdown')) {
                                return { ...filter, type: 'select', options: companies };
                            }
                            return filter;
                        });
                    }
                });
                return newConfig;
            });
        }
    }, [companies]);

    const validateDateFilters = (filters) => {
        console.log(filters)
        const hasStartDate = filters.hasOwnProperty('start_date') && filters.start_date;
        const hasEndDate = filters.hasOwnProperty('end_date') && filters.end_date;

        if ((hasStartDate && !hasEndDate) || (!hasStartDate && hasEndDate)) {
            setValidationError('Both start date and end date must be set');
            return false;
        }

        if (hasStartDate && hasEndDate) {
            const start = new Date(filters.start_date);
            const end = new Date(filters.end_date);

            if (end < start) {
                setValidationError('End date cannot be earlier than start date');
                return false;
            }
        }

        setValidationError('');
        return true;
    };

    useEffect(() => {
        const fetchData = async () => {
            const currentFilters = filterStates[endpoint];

            const hasDateFilters = filterConfig.some(filter => filter.type === 'date');
            if (hasDateFilters && !validateDateFilters(currentFilters)) {
                return;
            }

            setIsLoading(true);
            try {

                const result = await fetchDataFromApi(endpoint, currentFilters);
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [endpoint, filterStates[endpoint], filterConfig]);


    if (isLoading) return <Backdrop className={classes.backdrop} open={true}><CircularProgress /></Backdrop>;
    if (!data) return <div>No data available.</div>;

    const handleFilterChange = (newFilters) => {
        setFilterStates(prev => ({
            ...prev,
            [endpoint]: newFilters
        }));
    };

    return (
      <div className={classes.root}>
        <h1 className="text-2xl font-bold mb-4 capitalize">{pathName}</h1>

        <label htmlFor="endpoint" className={classes.label}>
          Select Analysis Type
        </label>
        <select
          className={classes.select}
          id="endpoint"
          value={endpoint}
          onChange={(e) => {
            setEndpoint(e.target.value);
            setValidationError("");
          }}
        >
          {Object.keys(config).map((key) => (
            <option value={key} key={key}>
              {key === "salaryrevenueanalysis"
                ? "Salary Revenue"
                : key === "userstats"
                ? "User Statistics"
                : key.includes("analysis")
                ? key.replace("analysis", "")[0].toUpperCase() +
                  key.slice(1, -8)
                : key[0].toUpperCase() + key.slice(1)}
            </option>
          ))}
        </select>

        <FilterForm
          filters={filterStates[endpoint]}
          setFilters={handleFilterChange}
          filterConfig={filterConfig}
          validationError={validationError}
        />

        {chartType && data && (
          <>
            <h2 className={classes.h2}>Data Graph</h2>
            <ChartComponent
              data={data}
              chartType={chartType}
              xKey={xKey}
              dataKey={barKey}
            />
          </>
        )}

        <h2 className={classes.h2}>Data Table</h2>
        {data && (
          <TableComponent data={data} columns={columns} endpoint={endpoint} />
        )}
      </div>
    );
};
export default AdminReports;