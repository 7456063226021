import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    formContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '3px',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    fieldContainer: {
        flex: '1 1 48%',
        minWidth: '200px',
    },
    label: {
        fontSize: '14px',
        fontWeight: '500',
        color: '#3E83FA',
        marginBottom: '8px',
        display: 'block',
    },
    input: {
        width: '50%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
    },
    select: {
        width: '50%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: '#fff',
    },
    errorText: {
        color: '#FF0000',
        fontSize: '12px',
        marginTop: '4px',
    },
});

const FilterForm = ({ filters, setFilters, filterConfig, validationError }) => {
    const classes = useStyles();

    const handleFilterChange = (name, value) => {
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    return (
        <div className={classes.formContainer}>
            {filterConfig?.map((filter) => (
                <div key={filter.name} className={classes.fieldContainer}>
                    <label htmlFor={filter.name} className={classes.label}>
                        {filter.label}
                    </label>
                    {filter.type === 'date' ? (
                        <input
                            type="date"
                            id={filter.name}
                            value={filters[filter.name] || ''}
                            onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                            className={classes.input}
                        />
                    ) : filter.type === 'select' ? (
                        <select
                            id={filter.name}
                            value={filters[filter.name] || ''}
                            onChange={(e) => handleFilterChange(filter.name, e.target.value)}
                            className={classes.select}
                        >
                            <option value="">Select {filter.label}</option>
                            {filter.options?.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    ) : null}
                </div>
            ))}
            {validationError && (
                <div className={classes.errorText}>{validationError}</div>
            )}
        </div>
    );
};

export default FilterForm;