import { put, takeLatest, call } from "redux-saga/effects";
import {
  SIGNING_ACTION,
  SIGNING_LOADING,
  SIGNING_SUCCESS,
  SIGNING_ERROR,
  SIGN_OUT_ACTION,
  SIGN_OUT_SUCCESS,
  COMPANY_INFORMATION_ACTION,
  COMPANY_INFORMATION_LOADING,
  COMPANY_INFORMATION_SUCCESS,
  COMPANY_INFORMATION_ERROR,
  REFRESH_TOKEN_ACTION,
  REFRESH_TOKEN_LOADING,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  COMPANY_ENUMERATE_ACTION,
  COMPANY_ENUMERATE_LOADING,
  COMPANY_ENUMERATE_SUCCESS,
  COMPANY_ENUMERATE_ERROR,
  COUNTRY_ENUMERATE_ACTION,
  COUNTRY_ENUMERATE_LOADING,
  COUNTRY_ENUMERATE_SUCCESS,
  COUNTRY_ENUMERATE_ERROR,
  CURRENCY_ENUMERATE_ACTION,
  CURRENCY_ENUMERATE_LOADING,
  CURRENCY_ENUMERATE_SUCCESS,
  CURRENCY_ENUMERATE_ERROR,
  TIMEZONE_ENUMERATE_ACTION,
  TIMEZONE_ENUMERATE_LOADING,
  TIMEZONE_ENUMERATE_SUCCESS,
  TIMEZONE_ENUMERATE_ERROR,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./types";
import Api from "./api";
import { Constants } from "../../lib/constant";
import history from "../../history";

function* LoginSaga(data) {
  yield put({ type: SIGNING_LOADING });
  try {
    let res = yield call(Api.Login, data.payload);

    if (res && res.data && res.data.token) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      yield put({ type: SIGNING_SUCCESS, payload: res.data });
      if (data.redirectURI && data.redirectURI.includes("payments")) {
        if (res && res.data && res.data.user && res.data.user.is_super) {
          history.push(data.redirectURI);
        } else {
          localStorage.clear();
          yield put({
            type: SIGNING_ERROR,
            payload: "Permission denied.",
          });
        }
      } else if (data.redirectURI) {
        history.push(data.redirectURI);
      } else if (res && res.data && res.data.user && res.data.user.is_super) {
        history.push("/backoffice/dashboard/overview");
      } else if (res && res.data && res.data.user && res.data.user.is_admin) {
        history.push("/company/dashboard/Employees");
      } else {
        localStorage.clear();
        yield put({
          type: SIGNING_ERROR,
          payload: [Constants.ERROR.PERMISSION_ERROR],
        });
      }
    } else if (res && res.message) {
      yield put({ type: SIGNING_ERROR, payload: res.message });
    } else {
      yield put({
        type: SIGNING_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: SIGNING_ERROR, payload: error.message });
  }
}

function* LogoutSaga() {
  yield put({ type: SIGN_OUT_SUCCESS });
}

function* getCompanySaga() {
  yield put({ type: COMPANY_INFORMATION_LOADING });
  try {
    let res = yield call(Api.getCompany);
    yield put({ type: COMPANY_INFORMATION_SUCCESS, payload: res.data });
  } catch (error) {
    yield put({ type: COMPANY_INFORMATION_ERROR, payload: error.message });
  }
}

function* refreshTokenSaga(data) {
  yield put({ type: REFRESH_TOKEN_LOADING });
  try {
    let res = yield call(Api.refreshToken, data.payload);
    if (res?.data?.token) {
      localStorage.setItem("token", res.data.token);
    }
    yield put({ type: REFRESH_TOKEN_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: REFRESH_TOKEN_ERROR, payload: error.message });
  }
}

function* getCompaniesSaga() {
  yield put({ type: COMPANY_ENUMERATE_LOADING });
  try {
    let res = yield call(Api.getCompanies);
    if (res?.data?.length)
      yield put({ type: COMPANY_ENUMERATE_SUCCESS, payload: res.data });
    else
      yield put({
        type: COMPANY_ENUMERATE_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
  } catch (error) {
    yield put({ type: COMPANY_ENUMERATE_ERROR, payload: error.message });
  }
}

function* getCountriesSaga() {
  yield put({ type: COUNTRY_ENUMERATE_LOADING });
  try {
    let res = yield call(Api.getCountries);
    if (res?.data?.length)
      yield put({ type: COUNTRY_ENUMERATE_SUCCESS, payload: res.data });
    else
      yield put({
        type: COUNTRY_ENUMERATE_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
  } catch (error) {
    yield put({ type: COUNTRY_ENUMERATE_ERROR, payload: error.message });
  }
}

function* getCurrenciesSaga() {
  yield put({ type: CURRENCY_ENUMERATE_LOADING });
  try {
    let res = yield call(Api.getCurrencies);
    if (res?.data?.length)
      yield put({ type: CURRENCY_ENUMERATE_SUCCESS, payload: res.data });
    else
      yield put({
        type: COUNTRY_ENUMERATE_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
  } catch (error) {
    yield put({ type: CURRENCY_ENUMERATE_ERROR, payload: error.message });
  }
}

function* getTimeZonesSaga() {
  yield put({ type: TIMEZONE_ENUMERATE_LOADING });
  try {
    let res = yield call(Api.getTimeZones);
    if (res?.data?.length)
      yield put({ type: TIMEZONE_ENUMERATE_SUCCESS, payload: res.data });
    else
      yield put({
        type: COUNTRY_ENUMERATE_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
  } catch (error) {
    yield put({ type: TIMEZONE_ENUMERATE_ERROR, payload: error.message });
  }
}

function* forgotPasswordSaga(data) {
  yield put({ type: FORGOT_PASSWORD_LOADING });
  try {
    let res = yield call(Api.forgotPassword, data.payload);
    if (res && res.data) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data });
    } else if (res && res.message) {
      yield put({ type: FORGOT_PASSWORD_ERROR, payload: res.message });
    } else {
      yield put({
        type: FORGOT_PASSWORD_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_ERROR, payload: error.message });
  }
}

function* resetPasswordSaga(data) {
  yield put({ type: RESET_PASSWORD_LOADING });
  try {
    let res = yield call(Api.resetPassword, data.payload);
    if (res && res.data) {
      yield put({ type: RESET_PASSWORD_SUCCESS, payload: res.data });
    } else if (res && res.message) {
      yield put({ type: RESET_PASSWORD_ERROR, payload: res.message });
    } else {
      yield put({
        type: RESET_PASSWORD_ERROR,
        payload: [Constants.ERROR.INTERNAL_SERVER],
      });
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR, payload: error.message });
  }
}

function* watchLoginAsync() {
  yield takeLatest(SIGNING_ACTION, LoginSaga);
  yield takeLatest(SIGN_OUT_ACTION, LogoutSaga);
  yield takeLatest(COMPANY_INFORMATION_ACTION, getCompanySaga);
  yield takeLatest(REFRESH_TOKEN_ACTION, refreshTokenSaga);
  yield takeLatest(COMPANY_ENUMERATE_ACTION, getCompaniesSaga);
  yield takeLatest(COUNTRY_ENUMERATE_ACTION, getCountriesSaga);
  yield takeLatest(CURRENCY_ENUMERATE_ACTION, getCurrenciesSaga);
  yield takeLatest(TIMEZONE_ENUMERATE_ACTION, getTimeZonesSaga);
  yield takeLatest(FORGOT_PASSWORD_ACTION, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD_ACTION, resetPasswordSaga);
}

export default watchLoginAsync;
