import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from '@material-ui/core/Button';
import readXlsxFile from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import CommonButton from "../../components/button";
import axios from "axios";
import { Constants } from "../../lib/constant";
import Loading from "../loading";

var fileDownloader, fileUploader;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0px",
  },
  container: {
    width: "350px",
    backgroundColor: theme.colors.White,
    boxShadow: "0px 0px 10px #CFD9EA80",
    borderRadius: "15px",
    padding: "50px 60px",
  },
  title: {
    color: theme.colors.DodgerBlue,
    fontSize: "24px",
    fontFamily: theme.fontFamily.MARKPROBOLD,
    marginBottom: "20px",
  },
  subText: {
    color: theme.colors.Violet,
    fontSize: "18px",
    fontFamily: theme.fontFamily.MARKPRO,
    marginBottom: "10px",
  },
  fileContainer: {
    backgroundColor: theme.colors.Zumthor,
    padding: "5px 10px",
    top: "15px",
    position: "relative",
    borderRadius: "3px",
    wordBreak: "break-word",
  },
  fileName: {
    color: theme.colors.DodgerBlue,
    fontSize: "14px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
  },
  crossIcon: { marginLeft: "8px", marginBottom: "-4px", cursor: "pointer" },
  bingoImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
  },
  modalPaper: {
    position: "absolute",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.5)',
    padding: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

}));

function BulkUpdateEmployees({ setBulkEmployees }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyDashboardReducer = useSelector((state) => state.companyDashboardReducer);

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [bulkUpdates, setBulkUpdates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleConfirmUpload = () => {
    fileUploader.click();
    setShowModal(false);
  };

  const handleCancelUpload = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const { onBoardSuccess, error } = companyDashboardReducer;
    if (onBoardSuccess) {
      setOpenModal(true);
    }

    if (error) {
      setErrors(Array.isArray(error) ? error : [error]);
      if (file) setFile(null);
    } else {
      setErrors([]);
    }
  }, [companyDashboardReducer]);

  useEffect(() => {
    if (errors.length) {
      const ele = document.getElementsByClassName("error-container");
      if (ele.length) ele[0].scrollIntoView(false);
    }
  }, [errors]);

  const handleFileUpload = async (uploadedFile) => {
    try {
      const rows = await readXlsxFile(uploadedFile);
      const headers = rows[0]; // First Row Being The Header
      const data = rows.slice(1); // Remove the header, the remaining rows are the data

    
      const formattedData = data.map(row => {
        let obj = {};
        row.forEach((value, index) => {
          obj[headers[index]] = value;
        });
        return obj;
      });
   
      const response = await axios.patch(`${Constants.BASE_URL}/admin/employees-bulk-update`, {
        data: formattedData
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
      });

      const { users_updated, data: responseData, errors } = response.data;
      if (errors && errors.length) {
        setErrors(errors);
      } else {
        setBulkUpdates(responseData);
        setOpenModal(true);
      }
    } catch (error) {
      setErrors([error.response?.data?.details?.error || "An error occurred while uploading the file."]);
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{"Sanaam - Invite Employees"}</title>
        <meta property="og:title" content={"Sanaam - Invite Employees"} />
      </Helmet>
      <Loading showLoader={companyDashboardReducer?.loading} />
      <div className={classes.container}>
        <div className={classes.title}>Bulk Employee Update</div>
        <div className={classes.subText}>
          Download our template to import your
          <br /> employees update to Sanaam
        </div>
        <div style={{ marginBottom: "40px" }}>
          <CommonButton
            stylevariant="secondary2"
            type="button"
            text={"Download Template"}
            onClick={() => {
              fileDownloader.click();
            }}
            icon={"/images/download.png"}
          />
          <a
            ref={(ref) => (fileDownloader = ref)}
            href={"/Bulk template list.xlsx"}
            download={`Bulk template list`}
            style={{ display: "none" }}
          >
            download
          </a>
        </div>
        <div className={classes.subText}>
          After downloading and filling our
          <br /> template please upload it here
        </div>
        <div style={{ marginBottom: "40px" }}>
          <CommonButton
            stylevariant="secondary2"
            type="button"
            text={"Upload Employee Sheets"}
            onClick={handleButtonClick}
            icon={"/images/upload.png"}
          />
          <Modal open={showModal} onClose={handleCancelUpload} className={classes.modal}>
            <div className={classes.paper}>
              <p>This action cannot be revoked. Are you sure you want to proceed?</p>
              <div className={classes.buttonContainer}>
                <Button variant="contained" style={{ backgroundColor: '#3E83FA' }} onClick={handleConfirmUpload}>Yes, Proceed</Button>
                <Button variant="contained" style={{ backgroundColor: '#FF3B59' }} onClick={handleCancelUpload}>Cancel</Button>
              </div>
            </div>
          </Modal>
          <input
            type="file"
            ref={(ref) => (fileUploader = ref)}
            style={{ display: "none" }}
            onClick={(event) => { event.target.value = null; }}
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              setFile(selectedFile);
            }}
            accept=".xlsx"
          />
          {file?.name && (
            <span className={classes.fileContainer}>
              <span className={classes.fileName}>{file.name}</span>
              <img
                src={"/images/cross.png"}
                alt="cross"
                className={classes.crossIcon}
                onClick={() => setFile(null)}
              />
            </span>
          )}
          {errors.length ? (
            <div className={"error-container"}>
              <div>
                You have the following errors, please fix them and re-upload the
                sheet again.
              </div>
              <br />
              {errors.map((each, index) => (
                <div key={index}>{each}</div>
              ))}
            </div>
          ) : null}
        </div>

        <div style={{ marginTop: "70px" }}>
          <CommonButton
            type="button"
            text={"Submit"}
            stylevariant="secondary1"
            disabled={!file?.name}
            onClick={() => {
              if (file) {
                handleFileUpload(file); // Trigger file processing and upload
              }
            }}
          />
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setBulkEmployees(false);
          setFile(null);
        }}
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <div className={classes.container}>
            <div className={classes.bingoImage}>
              <img src={"/images/success_bingo.svg"} alt="success_bingo" />
            </div>
            <div className={classes.subText} style={{ textAlign: "center" }}>
              Updated successful!
            </div>
            <div style={{ marginTop: "70px" }}>
              <CommonButton
                type="button"
                text={"Done"}
                stylevariant="secondary1"
                onClick={() => {
                  setOpenModal(false);
                  setBulkEmployees(false);
                  setFile(null);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default BulkUpdateEmployees;
