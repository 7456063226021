import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar";
import Employees from "../../components/employees";
import DailyAttendance from "../../components/dailyAttendance";
import Reports from "../../components/reports"
// import Leaves from "../../components/leaves";
import OvertimeRequests from "../../components/overtime";
import Loading from "../../components/loading";

const useStyles = makeStyles(() => ({ root: { display: "flex" } }));

function CompanyDashboard({ match, location }) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("Employees");

  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });

  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });

  useEffect(() => {
    if (match?.params?.activeTab && match.params.activeTab !== activeTab) {
      setActiveTab(match.params.activeTab);
    }
  }, [match, activeTab, setActiveTab]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`Sanaam - ${activeTab}`}</title>
        <meta property="og:title" content={`Sanaam - ${activeTab}`} />
      </Helmet>
      <Loading
        showLoader={
          dashboardReducer?.loading ||
          companyDashboardReducer?.loading ||
          loginReducer?.loading
        }
      />
      <Sidebar
        baseURL={"/company/dashboard"}
        activeTab={activeTab}
        tabs={[
          {
            name: "Employees",
            icon: "Employees_Icon.svg",
            isActive: true,
            pathName: "Employees",
          },
          {
            name: "Leaves",
            icon: "Daily Attendance Icon.svg",
            isActive: false,
            pathName: "Leaves",
          },
          {
            name: "Requests",
            icon: "Requests Icon.svg",
            isActive: true,
            pathName: "Requests",
          },
          {
            name: "Reports",
            icon: "Statistics Icon.svg",
            isActive: true,
            comingSoon: false,
            pathName: "Reports",
          },
        ]}
      />
      {activeTab === "Employees" && <Employees location={location} />}
      {activeTab === "Daily Attendance" && <DailyAttendance />}
      {/* {activeTab === "Leaves" && <Leaves />} */}
      {activeTab === "Requests" && <OvertimeRequests />}
      {activeTab === "Reports" && <Reports />}
    </div>
  );
}

export default CompanyDashboard;
