


export const analysisConfig = {
    companyanalysis: {
        columns: ["Company", "Total Employees", "Total Registered Employees", "Rate Registered Employees", 
                  "Total Active Employees", "Rate Active Employees from Registered", "Rate Active Employees from Total", 
                  "Total Request Amounts", "Total Revenue from Fees", "Total Transactions", "Avg Fees per Transaction", 
                  "Rate Avg Fees per Transaction"],
        xKey: "company",
        barKey: "total_transactions",
        chartType: "line",
        filters: [
            { name: "company", label: "Company", type: "select", options: [] },
        ],
        pathName: "Company Analysis",
    },
    userstats: { 
        columns: ["Period", "Total Users", "Total Users Change", "Total Active Users", 
                  "Active Users Change Rate", "Monthly Active Users", "Monthly Active Users Change Rate", 
                  "Monthly Active Users Rate Total", "Monthly Active Users Rate Active"], 
        xKey: "period_start", 
        barKey: "total_users",
        chartType: "line",
        filters: [
            { name: "start_date", label: "Start Date", type: "date" },
            { name: "end_date", label: "End Date", type: "date" },
        ],
        pathName: "User Analysis",
    },
    revenueanalysis: { 
        columns: ["Period", "Total Transactions", "Total Requested Amount", 
                  "Total Revenue from Fees", "Avg Requested Amount per Transaction", "Avg Fees per Transaction"], 
        xKey: "start_date", 
        barKey: "total_transactions",
        chartType: "line",
        filters: [
            { name: "start_date", label: "Start Date", type: "date" },
            { name: "end_date", label: "End Date", type: "date" },
        ],
        pathName: "Revenue Analysis",
    },
    transactionanalysis: { 
        columns: ["Period", "Ranges", "Total Transactions", "Total Amount"], 
        xKey: "month", 
        barKey: "total_transactions",
        chartType: "line",
        filters: [
            { name: "start_date", label: "Start Date", type: "date" },
            { name: "end_date", label: "End Date", type: "date" },
        ], 
        pathName: "Transaction Analysis",
    },
    clientanalysis: { 
        columns: ["Total Transaction", "Active Month", "Average Requests Per Month", "Start Date", 
                  "Company Name", "Client Name", "Net Salary", "Payment", "Fees", "Total Payment"], 
        xKey: "client_name", 
        barKey: "total_transaction",
        chartType: null,
        filters: [
            { name: "company", label: "Company", type: "select", options: [] },
            { name: "is_active", label: "Is Active", type: "select", options: [{ value: true, label: "True" }, { value: false, label: "False" }] },
            { name: "start_date", label: "Start Date", type: "date" },
            { name: "end_date", label: "End Date", type: "date" },
        ],
        pathName: "Client Analysis",
    },
    salaryrevenueanalysis: { 
        columns: ["Period", "Total Salaries", "Total Active Salaries", "Total Request Amounts", 
                  "Total Revenue from Fees", "Change In Total Revenue Rate", "Revenue Rate", 
                  "Change In Revenue Rate", "Request Rate from Total Salaries", "Request Rate from Total Active Salaries"], 
        xKey: "period_start", 
        barKey: "total_salaries",
        chartType: "line",
        filters: [
            { name: "start_date", label: "Start Date", type: "date" },
            { name: "end_date", label: "End Date", type: "date" },
        ],
        pathName: "Salary Revenue Analysis",
    },
};