import axios from "axios";
import { Constants } from "./constant";
import history from "../history";

const isObject = (data, messages) => {
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (Array.isArray(data[key])) {
      for (let j = 0; j < data[key].length; j++) {
        const element = data[key][j];
        if (Array.isArray(element)) {
          isArray(element, messages);
        }
        if (typeof element === "object") {
          isObject(element, messages);
        }
        if (typeof element === "string") {
          const key_parts = key.split("_");
          let modified_key_parts = [];

          for (let i = 0; i < key_parts.length; i++) {
            let key_part = key_parts[i];
            key_part =
              key_part.charAt(0).toLocaleUpperCase() +
              key_part.slice(1, key_part.length);
            modified_key_parts.push(key_part);
          }

          let modifiedMessage = element
            .toLowerCase()
            .replace("this field", modified_key_parts.join(" "));

          modifiedMessage = modifiedMessage
            .toLowerCase()
            .replace("this value", modified_key_parts.join(" "));

          if (modifiedMessage?.length) {
            modifiedMessage =
              modifiedMessage.charAt(0).toLocaleUpperCase() +
              modifiedMessage.slice(1, modifiedMessage.length);
          }
          if (messages.findIndex((each) => each === modifiedMessage) < 0)
            messages.push(modifiedMessage);
        }
      }
    }
    if (typeof data[key] === "object") {
      isObject(data[key], messages);
    }
  }
};

const isArray = (data, messages) => {
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    if (Array.isArray(element)) {
      isArray(element, messages);
    }
    if (typeof element === "object") {
      isObject(element, messages);
    }
    if (typeof element === "string") {
      if (messages.findIndex((each) => each === element) < 0);
      messages.push(element);
    }
  }
};

export let domainUrl = process.env.REACT_APP_API_URL || Constants.BASE_URL;

const apiInstance = axios.create({
  baseURL: domainUrl,
  headers: { "Content-Type": "application/json" },
  timeout: 1000 * 60 * 1.5,
});

apiInstance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      const originalRequest = error.response.config;
      if (error?.response?.data?.message) {
        error.response.data.message = [error.response.data.message];
      }
      if (originalRequest?.url === "/auth/login") {
        return error.response;
      } else if (
        !originalRequest._retry &&
        originalRequest?.url !== "/auth/login"
      ) {
        originalRequest._retry = true;
        try {
          const res = await refreshAccessToken();
          if (res && res.data && res.data.data && res.data.data.token) {
            localStorage.setItem("token", res.data.data.token);
            return apiInstance(originalRequest);
          } else {
            await expireUserToken();
            return error.response;
          }
        } catch (e) {
          await expireUserToken();
          console.log("Refresh Token Catch", e);
          return error.response;
        }
      } else {
        await expireUserToken();
        return error.response;
      }
    } else {
      let messages = [];
      if (error?.response?.data?.details) {
        const { details } = error?.response?.data;
        if (typeof details === "object") {
          isObject(details, messages);
        }
        if (Array.isArray(details)) {
          isArray(details, messages);
        }
      }
      if (!messages?.length) {
        messages = [
          error?.response?.data?.message
            ? error.response.data.message
            : error?.message
            ? error.message
            : Constants.ERROR.INTERNAL_SERVER,
        ];
      }
      return {
        status: error?.response?.status || 500,
        data: {
          code: error?.response?.data?.code || 500,
          message: messages,
          details: error?.response?.data?.details || {},
        },
      };
    }
  }
);

const refreshAccessToken = () => {
  console.log("Refresh Token");
  const url = domainUrl + `/auth/refresh-token`;
  const result = axios.post(url, { token: localStorage.getItem("token") });
  return result;
};

const expireUserToken = () =>
  new Promise(async (resolve, reject) => {
    localStorage.clear();
    history.push("/");
    resolve(true);
  });

export default apiInstance;
