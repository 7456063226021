import { Router, Route, Switch, Redirect } from "react-router-dom";
import React from "react";
import MainLayout from "./containers/mainLayout";
import Login from "./containers/login";
import ForgotPassword from "./containers/forgotPassword";
import ResetPassword from "./containers/resetPassword";
import EmployeeConfirmRegistration from "./containers/employeeConfirmRegistration";
import ComapnyRegistration from "./containers/companyRegistration";
import WithdrawApprovalRequest from "./containers/withdrawApprovalRequest";
import AppDownload from "./containers/appDownload";
import Dashboard from "./containers/dashboard";
import CompanyDashboard from "./containers/companyDashboard";
import store from "./store";
import { Provider } from "react-redux";
import history from "./history";

const MainRouter = () => {
  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  const NotAuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        JSON.parse(localStorage.getItem("user"))?.is_super ? (
          <Redirect to="/backoffice/dashboard/overview" />
        ) : JSON.parse(localStorage.getItem("user"))?.is_admin ? (
          <Redirect to="/company/dashboard/Employees" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  const SuperAdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return JSON.parse(localStorage.getItem("user"))?.is_super ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={`/login${
              props?.location?.pathname
                ? `?redirectURI=${props.location.pathname.toLowerCase()}`
                : ""
            }`}
          />
        );
      }}
    />

  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        JSON.parse(localStorage.getItem("user"))?.is_admin ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={`/login${
              props?.location?.pathname
                ? `?redirectURI=${props.location.pathname.toLowerCase()}`
                : ""
            }`}
          />
        )
      }
    />
  );

  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <NotAuthRoute path="/login" exact component={Login} />
          <NotAuthRoute
            path="/auth/forgot-password"
            exact
            component={ForgotPassword}
          />
          <NotAuthRoute
            path="/auth/reset-password/:tid/:uid"
            exact
            component={ResetPassword}
          />
          <NotAuthRoute
            path="/onboarding/:cid/employees/:eid"
            exact
            component={EmployeeConfirmRegistration}
          />
          <NotAuthRoute
            path="/onboarding/:cid"
            exact
            component={ComapnyRegistration}
          />
          <NotAuthRoute path="/app/download" exact component={AppDownload} />
          <SuperAdminRoute
            path="/backoffice/dashboard/:activeTab"
            exact
            component={Dashboard}
          />
          <SuperAdminRoute
            path="/payments/:payment_id"
            exact
            component={WithdrawApprovalRequest}
          />
          <AdminRoute
            path="/company/dashboard/:activeTab"
            exact
            component={CompanyDashboard}
          />
          <Redirect from="/" to={"/login"}></Redirect>
        </Switch>
      </Router>
    </Provider>
  );
};

export default MainRouter;
