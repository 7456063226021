import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import queryString from "query-string";
import theme from "../../theme";
import CommonButton from "../button";
import CommonDropDown from "../dropdown";
import CompanyEmployeeDetail from "./detail";
import InviteEmployees from "./inviteEmployees";
import BulkUpdateEmployees from "./bulkUpdateEmployees";
import EmployeeDetail from "./employeeDetail";
import EditEmployeeDetail from "./editEmployeeDetail";
import SearchEmployee from "./searchEmployee";
import { getEmployeeListAction } from "../../containers/dashboard/action";
import { updateEmployeeAction, createPayStackProfileAction } from "../../containers/companyDashboard/action";
import { getDeductionListAction } from "../../containers/employeeConfirmRegistration/action";
import history from "../../history";

let employeeOptions = [];

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.White,
    width: "100%",
    minHeight: "calc(100vh - 175px)",
    padding: "45px 90px",
    marginLeft: "220px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      color: theme.colors.Violet,
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      fontSize: "24px",
      "& .companyLabel": { color: "#9FC1FD", cursor: "pointer" },
      "& .companyNameLabel": {
        color: theme.colors.DodgerBlue,
        cursor: "pointer",
      },
    },
  },
  backLink: {
    marginTop: "15px",
    color: theme.colors.DodgerBlueLight,
    fontSize: "16px",
    fontFamily: theme.fontFamily.MARKPROMEDIUM,
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    "& .label": { marginLeft: "5px", position: "relative", top: 2 },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "15px",
    float: "right",
    "& .title": {
      color: theme.colors.DodgerBlue,
      fontSize: "16px",
      fontFamily: theme.fontFamily.MARKPROMEDIUM,
      marginRight: 20,
    },
  },
}));

function Employees({ location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getEmployeeListMethod = useCallback(
    (data) => dispatch(getEmployeeListAction(data)),
    [dispatch]
  );

  const updateEmployeeMethod = useCallback(
    (data, employee_id) => dispatch(updateEmployeeAction(data, employee_id)),
    [dispatch]
  );

  const createPayStackProfileActionMethod = useCallback(
    (data) => dispatch(createPayStackProfileAction(data)),
    [dispatch]
  );

  const getDeductionListMethod = useCallback(
    () => dispatch(getDeductionListAction()),
    [dispatch]
  );

  const loginReducer = useSelector((state) => {
    return state.loginReducer;
  });
  const dashboardReducer = useSelector((state) => {
    return state.dashboardReducer;
  });
  const companyDashboardReducer = useSelector((state) => {
    return state.companyDashboardReducer;
  });
  const employeeRegistrationReducer = useSelector((state) => {
    return state.employeeRegistrationReducer;
  });

  const [inviteEmployees, setInviteEmployees] = useState(false);
  const [bulkUpdateEmployees, setBulkUpdateEmployees] = useState(false);
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [editEmployeeDetail, setEditEmployeeDetail] = useState(null);
  const [searchEmployee, setSearchEmployee] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    if (location?.search) {
      const parsed = queryString.parse(location.search);
      if (parsed?.employeeId) {
        if (
          dashboardReducer?.employeeList?.data?.length &&
          (!employeeDetail?.id || employeeDetail.id !== parsed.employeeId)
        ) {
          const record = dashboardReducer?.employeeList?.data.find(
            (each) => each?.id === parsed?.employeeId
          );
          if (record) {
            setEmployeeDetail(record);
            setSearchEmployee(false);
          } else {
            setEmployeeDetail(null);
          }
        }
      } else {
        setEmployeeDetail(null);
      }
    } else {
      setEmployeeDetail(null);
    }
  }, [location, dashboardReducer, employeeDetail]);

  useEffect(() => {
    getDeductionListMethod();
  }, [getDeductionListMethod]);

  useEffect(() => {
    if (
      companyDashboardReducer?.updateEmployeeSuccess &&
      loginReducer?.companyInformation?.id
    ) {
      getEmployeeListMethod({
        company_id: loginReducer.companyInformation.id,
      });
    }
  }, [
    companyDashboardReducer,
    setEditEmployeeDetail,
    loginReducer,
    getEmployeeListMethod,
  ]);

  useEffect(() => {
    if (loginReducer?.companyInformation?.id) {
      getEmployeeListMethod({
        company_id: loginReducer.companyInformation.id,
      });
    }
  }, [loginReducer, getEmployeeListMethod]);

  useEffect(() => {
    if (dashboardReducer?.employeeList?.data?.length) {
      for (let i = 0; i < dashboardReducer.employeeList.data.length; i++) {
        const each = dashboardReducer.employeeList.data[i];
        if (employeeOptions.findIndex((rec) => rec.value === each.id) < 0)
          employeeOptions.push({
            value: each.id,
            label: `${each?.first_name} ${each?.last_name}`,
          });
      }
    }
  }, [dashboardReducer]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className="title">
          <div>
            {inviteEmployees
              ? "Invite Employees"
              : bulkUpdateEmployees
              ? "Bulk Update Employees"
              : editEmployeeDetail
              ? "Edit Employee Details"
              : "Employees"}
          </div>
          {employeeDetail && !editEmployeeDetail ? (
            <div style={{ marginTop: "15px" }}>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "#9FC1FD" }}
                  />
                }
              >
                <div
                  className="companyLabel"
                  onClick={() => {
                    history.push("/company/dashboard/Employees");
                  }}
                >
                  Employees
                </div>
                <div className="companyNameLabel">
                  {employeeDetail?.first_name} {employeeDetail?.last_name}
                </div>
              </Breadcrumbs>
            </div>
          ) : null}
          {inviteEmployees ||
          bulkUpdateEmployees ||
          editEmployeeDetail ||
          (searchEmployee && !employeeDetail) ? (
            <div
              className={classes.backLink}
              onClick={() => {
                setInviteEmployees(false);
                setBulkUpdateEmployees(false)
                setEditEmployeeDetail(null);
                setSearchEmployee(false);
                setSelectedEmployee(null);
                if (editEmployeeDetail) {
                  history.push(
                    `/company/dashboard/Employees?employeeId=${editEmployeeDetail?.id}`
                  );
                } else {
                  history.push("/company/dashboard/Employees");
                }
              }}
            >
              <img src="/images/right-arrow.png" alt="right-arrow" />
              <span className="label">Back</span>
            </div>
          ) : null}
        </div>
        {!(inviteEmployees || bulkUpdateEmployees || employeeDetail || searchEmployee) ? (
          
          <div style={{ display: "flex", gap: "10px" }}>
            <CommonButton
              type="button"
              text="Bulk Update Employees"
              stylevariant="primary"
              onClick={() => {
                setBulkUpdateEmployees(true);
              }}
              style={{ padding: "5px 20px" }}
              fontSize="15px"
              fontFamily={theme.fontFamily.MARKPROHEAVY}
            />
            <CommonButton
              type="button"
              text="Invite Employees"
              stylevariant="primary"
              onClick={() => {
                setInviteEmployees(true);
              }}
              style={{ padding: "5px 20px" }}
              fontSize="15px"
              fontFamily={theme.fontFamily.MARKPROHEAVY}
            />
          </div>
        ) : searchEmployee && !employeeDetail ? (
          <div className={classes.filterContainer}>
            <div className="title">Search by</div>
            <div
              style={{ width: "200px" }}
              onClick={() => {
                setSearchEmployee(true);
              }}
            >
              <CommonDropDown
                placeholder={"Search Employee"}
                options={employeeOptions}
                value={selectedEmployee}
                onChange={(value) => {
                  setSelectedEmployee(value);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
 
      {searchEmployee ? (
        <SearchEmployee
          selectedEmployee={selectedEmployee}
          updateEmployeeMethod={updateEmployeeMethod}
        />
      ) : inviteEmployees ? (
        <InviteEmployees setInviteEmployees={setInviteEmployees} />
      ) : bulkUpdateEmployees ? (
        <BulkUpdateEmployees setBulkEmployees={setBulkUpdateEmployees} />
      )
       : editEmployeeDetail ? (
        <EditEmployeeDetail
          editEmployeeDetail={editEmployeeDetail} 
          setEditEmployeeDetail={setEditEmployeeDetail}
          updateEmployeeMethod={updateEmployeeMethod}
          deductionList={employeeRegistrationReducer?.deductionList}
        />
      ) : employeeDetail ? (
        <EmployeeDetail
          setEditEmployeeDetail={setEditEmployeeDetail}
          employeeDetail={employeeDetail}
          updateEmployeeMethod={updateEmployeeMethod}
          createPayStackProfileActionMethod={createPayStackProfileActionMethod}
        />
      ) : (
        <CompanyEmployeeDetail
          setSearchEmployee={setSearchEmployee}
          updateEmployeeMethod={updateEmployeeMethod}
        />
      )}
    </div>
  );
}

export default Employees;
